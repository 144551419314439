import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDeepCompareEffect } from "react-use";
import { type InferType, array, date, number, object, string } from "yup";
import { DFlex } from "./GenericFlex";
import {
	GenericForm,
	IDateTime,
	IMultiSelect,
	INumber,
	ISelect,
	IText,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { DGrid } from "./GenericGrid";
import { NewLegType, legKindOf, noInvalidDate, noNaN } from "./helpers";

const fields = {
	id: number().transform(noNaN).label("ID"),
	jobId: number().transform(noNaN).label("Job"),
	legType: number().required().label("Type"),
	goodsIds: array().of(number().required()).required().min(1).label("Goods"),
	notes: string().label("Notes"),
	supplierInvoiceNumber: string().label("Supplier Invoice Number"),
	supplierInvoice: number().label("Supplier Invoice Status"),
	supplierInvoiceDate: date()
		.transform(noInvalidDate)
		.label("Supplier Invoice Date"),
	cost: number().transform(noNaN).label("Cost"),
	currencyId: string().nullable().label("Currency"),
	/* operation, ferry, customs */
	driverId: number().nullable().label("Driver"),
	truckId: number().nullable().label("Truck"),
	trailerId: number().nullable().label("Trailer"),
	subcontractorId: number().nullable().label("Subcontractor"),
	transferBusinessUnitId: number().nullable().label("Business Unit"),
	/* operation */
	truckTypeId: number().nullable().label("Truck Type"),
	trailerTypeId: number().nullable().label("Trailer Type"),
	collectionLocationId: number().nullable().label("Collection Location"),
	collectionDate: date().transform(noInvalidDate).label("Collection Date"),
	deliveryLocationId: number().nullable().label("Delivery Location"),
	deliveryDate: date().transform(noInvalidDate).label("Delivery Date"),
	/* storage */
	storageLocation: number().nullable().label("Storage Location"),
	storageStartDate: date().transform(noInvalidDate).label("Storage Start Date"),
	storageEndDate: date().transform(noInvalidDate).label("Storage End Date"),
	/* ferry */
	ferryRoute: string().label("Ferry Route"),
	ferrySailingDate: date().transform(noInvalidDate).label("Ferry Sailing Date"),
	ferryReferenceNumber: string().label("Ferry Reference Number"),
	/* customs */
	ucr: string().label("UCR"),
	mrn: string().label("MRN"),
	t1: string().label("T1"),
	clearanceLocation: string().label("Clearance Location"),
	clearanceDate: date().transform(noInvalidDate).label("Clearance Date"),
};

const useLegFormSchema = (defaultType?: number) => {
	const [type, setType] = useState<number | undefined>(defaultType);
	let schema = object(fields);
	if (type === NewLegType.Collection || type === NewLegType.CollectDeliver) {
		schema = schema.shape({
			collectionLocationId: fields.collectionLocationId.required(),
			collectionDate: fields.collectionDate.required(),
		});
	}
	if (type === NewLegType.Delivery || type === NewLegType.CollectDeliver) {
		schema = schema.shape({
			deliveryLocationId: fields.deliveryLocationId.required(),
			deliveryDate: fields.deliveryDate.required(),
		});
	}
	if (type === NewLegType.Blank) {
		schema = schema.shape({
			notes: fields.notes.required(),
		});
	}
	return [schema, (type: number) => setType(Number(type))] as const;
};

export type Leg = InferType<ReturnType<typeof useLegFormSchema>[0]>;
type LegFormProps = {
	defaultValues?: Partial<Leg>;
	isRecEnabled?: boolean;
	onRecommendationsButtonClick?: () => void;
	lLegTypes: LoadOptionsFn;
	lGoods: LoadOptionsFn;
	lLocations: LoadOptionsFn;
	lSubcontractors: LoadOptionsFn;
	lTruckTypes: LoadOptionsFn;
	lTrailerTypes: LoadOptionsFn;
	lDrivers: LoadOptionsFn;
	lTrucks: LoadOptionsFn;
	lTrailers: LoadOptionsFn;
	lBusinessUnits: LoadOptionsFn;
	lSupplierInvoiceTypes: LoadOptionsFn;
	lCurrencies: LoadOptionsFn;
	onSubmit: (data: Leg) => Promise<unknown>;
	onAddLocation?: (callback: (value: number) => void) => void;
	onChange?: (data: Leg) => void;
};
export const LegForm = (x: LegFormProps) => {
	const [schema, setType] = useLegFormSchema(x.defaultValues?.legType);
	const form = useForm<Leg>({
		resolver: yupResolver(schema),
		defaultValues: x.defaultValues,
	});
	const formValues = form.watch();
	useDeepCompareEffect(() => {
		x.onChange?.(formValues);
	}, [formValues, x.onChange]);
	const type = form.watch("legType");
	const kind = legKindOf(type);
	useEffect(() => {
		setType(type);
		form.clearErrors();
	}, [type, setType, form.clearErrors]);
	return (
		<GenericForm
			schema={schema}
			form={form}
			onSubmit={x.onSubmit}
			extraButtons={
				x.isRecEnabled && (
					<Button
						type="button"
						onClick={() => x.onRecommendationsButtonClick?.()}
					>
						✨ Recommendations
					</Button>
				)
			}
		>
			<DGrid>
				<ISelect n="legType" l={x.lLegTypes} />
			</DGrid>
			{kind && (
				<DFlex>
					<div style={{ width: "100%" }}>
						<IMultiSelect n="goodsIds" l={x.lGoods} />
						{kind === "storage" && (
							<>
								<ISelect n="storageLocation" l={x.lLocations} />
								<IDateTime n="storageStartDate" />
								<IDateTime n="storageEndDate" />
							</>
						)}
						{kind === "ferry" && (
							<>
								<IText n="ferryRoute" />
								<IDateTime n="ferrySailingDate" />
								<IText n="ferryReferenceNumber" />
							</>
						)}
						{kind === "clearCustoms" && (
							<>
								<IText n="ucr" />
								<IText n="mrn" />
								<IText n="t1" />
								<IText n="clearanceLocation" />
								<IDateTime n="clearanceDate" />
							</>
						)}
						{kind === "operation" && (
							<>
								<ISelect n="collectionLocationId" l={x.lLocations} />
								<IDateTime n="collectionDate" />
								<ISelect n="deliveryLocationId" l={x.lLocations} />
								<IDateTime n="deliveryDate" />
							</>
						)}
					</div>
					{kind !== "blank" && kind !== "storage" && (
						<div style={{ minWidth: "184px", width: "100%" }}>
							{kind === "operation" && (
								<>
									<ISelect n="truckTypeId" l={x.lTruckTypes} />
									<ISelect n="trailerTypeId" l={x.lTrailerTypes} />
								</>
							)}
							<ISelect n="driverId" l={x.lDrivers} />
							<ISelect n="truckId" l={x.lTrucks} />
							<ISelect n="trailerId" l={x.lTrailers} />
							<ISelect n="subcontractorId" l={x.lSubcontractors} />
							<ISelect n="transferBusinessUnitId" l={x.lBusinessUnits} />
						</div>
					)}
					<div style={{ width: "100%" }}>
						<DGrid columns="1fr .8fr">
							<INumber n="cost" step={0.01} />
							<ISelect n="currencyId" l={x.lCurrencies} />
						</DGrid>
						<IText n="supplierInvoiceNumber" />
						<ISelect n="supplierInvoice" l={x.lSupplierInvoiceTypes} />
						<IDateTime n="supplierInvoiceDate" />
						<ITextArea n="notes" />
					</div>
				</DFlex>
			)}
		</GenericForm>
	);
};
