import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { type ComponentProps, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type InferType, array, boolean, number, object, string } from "yup";
import { DFlex } from "./GenericFlex";
import {
	GenericForm,
	ICheckbox,
	IMultiSelect,
	ISelect,
	IText,
	type LoadOptionsFn,
} from "./GenericForm";
import { GenericPage } from "./GenericPage";
import type { JobTypeStatus } from "./api/JobApi";
import {
	type NewLegType,
	type TypedGridColumnProps,
	jobApi,
	jobTypeStatusNames,
	legTypeNames,
	toasted,
	useLookupJobTypeStatuses,
	useLookupLegTypes,
} from "./helpers";

const JobTypeSchema = object({
	id: number().label("ID"),
	isContainer: boolean().required().label("Is Container").default(false),
	isDefaultZeroVat: boolean()
		.required()
		.label("Is Default Zero Vat")
		.default(false),
	isExport: boolean().required().label("Is Export").default(false),
	isImport: boolean().required().label("Is Import").default(false),
	name: string().required().label("Name"),
	nominalCode: string().label("Nominal Code"),
	status: number().label("Status"),
	vatRate: number().min(0).max(100).label("Vat Rate"),
	defaultLegTypes: array()
		.of(number().required().label("Default Leg Types"))
		.required()
		.label("Default Leg Types"),
});

type JobTypeFormObject = InferType<typeof JobTypeSchema>;

type JobTypeFormProps = {
	defaultValues?: Partial<JobTypeFormObject>;
	onSubmit: (data: JobTypeFormObject) => void;
	lLegTypes: LoadOptionsFn;
	lJobTypeStatuses: LoadOptionsFn;
};

const JopTypeForm = ({
	defaultValues,
	onSubmit,
	lLegTypes,
	lJobTypeStatuses,
}: JobTypeFormProps) => {
	const form = useForm<JobTypeFormObject>({
		resolver: yupResolver(JobTypeSchema),
		defaultValues,
	});

	return (
		<GenericForm
			form={form}
			schema={JobTypeSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<IText n="name" />
					{defaultValues?.id && <ISelect n="status" l={lJobTypeStatuses} />}
					<IText n="vatRate" />
					<IText n="nominalCode" />
					<IMultiSelect n="defaultLegTypes" l={lLegTypes} />
				</div>
				<div>
					<ICheckbox n="isContainer" />
					<ICheckbox n="isDefaultZeroVat" />
					<ICheckbox n="isExport" />
					<ICheckbox n="isImport" />
				</div>
			</DFlex>
		</GenericForm>
	);
};

const JobTypeFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<JobTypeFormProps, "onSubmit" | "defaultValues">) => (
	<JopTypeForm
		defaultValues={defaultValues}
		lLegTypes={useLookupLegTypes()}
		lJobTypeStatuses={useLookupJobTypeStatuses()}
		onSubmit={async (data) => {
			const { id, status, ...rest } = data;
			const processData = async () => {
				if (id) {
					await jobApi.jobType.jobTypeUpdate({
						id,
						status: status as JobTypeStatus,
						...rest,
					});
				} else await jobApi.jobType.jobTypeCreate({ ...rest });
				onSubmit(data);
			};
			await toasted(
				processData(),
				id ? "Updating Job Type" : "Creating Job Type",
			);
		}}
	/>
);

type JobType = JobTypeFormObject & {
	id: number;
	isContainerString: string;
	isDefaultZeroVatString: string;
	isExportString: string;
	isImportString: string;
	statusString: string;
	vatRateString: string;
	defaultLegTypesString: string;
};

const defaultColumns: TypedGridColumnProps<JobType>[] = [
	{ field: "id", title: "ID" },
	{ field: "name", title: "Name" },
	{ field: "nominalCode", title: "Nominal Code" },
	{ field: "statusString", title: "Status" },
	{ field: "isContainerString", title: "Is Container" },
	{ field: "isDefaultZeroVatString", title: "Is Default Zero Vat" },
	{ field: "isExportString", title: "Is Export" },
	{ field: "isImportString", title: "Is Import" },
	{ field: "vatRateString", title: "Vat Rate" },
	{ field: "defaultLegTypesString", title: "Default Leg Types" },
];

const useFetchData = (): ComponentProps<
	typeof GenericPage<JobType>
>["data"] => {
	const _jobTypes = useQuery({
		queryKey: ["jobApi.jobType.jobTypeList"],
		queryFn: () => jobApi.jobType.jobTypeList({}).then((x) => x.data.data),
		initialData: [],
	});
	const jobTypes = useMemo(
		() =>
			_jobTypes.data.map((x): JobType => {
				return {
					id: x.id ?? "?",
					defaultLegTypes: x.defaultLegTypes,
					name: x.name,
					isContainer: x.isContainer,
					isDefaultZeroVat: x.isDefaultZeroVat,
					isExport: x.isExport,
					isImport: x.isImport,
					status: x.status,
					vatRate: x.vatRate ?? 0,
					defaultLegTypesString: x.defaultLegTypes
						.map((x) => legTypeNames[x as NewLegType])
						.join(", "),
					isContainerString: x.isContainer ? "Yes" : "No",
					isDefaultZeroVatString: x.isDefaultZeroVat ? "Yes" : "No",
					isExportString: x.isExport ? "Yes" : "No",
					isImportString: x.isImport ? "Yes" : "No",
					statusString: jobTypeStatusNames[x.status],
					vatRateString: `${x.vatRate ?? 0}%`,
					nominalCode: x.nominalCode ?? "",
				};
			}),
		[_jobTypes.data],
	);
	return {
		data: jobTypes,
		retry: _jobTypes.refetch,
		loading: _jobTypes.isFetching,
	};
};

export const JobTypesPage2 = () => {
	const data = useFetchData();
	const handleDelete = (id: number) =>
		toasted(
			jobApi.jobType.jobTypeDelete(id).then(data.retry),
			"Deleting Job Type",
		);
	const getForm = (
		id: number | undefined,
		onSubmit: (data: JobTypeFormObject) => void,
	) => {
		let defaultValues: Partial<JobTypeFormObject> = {};
		if (id) defaultValues = data.data.find((x) => x.id === id) ?? {};
		return (
			<JobTypeFormWithDTO onSubmit={onSubmit} defaultValues={defaultValues} />
		);
	};
	return (
		<GenericPage
			pageTitle="Job Types"
			name="Job Types"
			data={data}
			onDelete={handleDelete}
			defaultColumns={defaultColumns}
			getForm={getForm}
		/>
	);
};
