import { useMutation, useQuery } from "@tanstack/react-query";
import { isNil, omitBy } from "lodash";
import { useState } from "react";
import { queryClient } from "../client/main";
import { type Leg, LegForm } from "./LegForm";
import type { LegFormDataResponse } from "./api/JobApi";
import {
	NewLegType,
	NewSupplierInvoiceType,
	jobApi,
	toasted,
	useLookupBusinessUnits,
	useLookupCurrencies,
	useLookupDrivers,
	useLookupGoods,
	useLookupLegTypes,
	useLookupLocations,
	useLookupSubcontractors,
	useLookupSupplierInvoiceTypes,
	useLookupTrailerTypes,
	useLookupTrailers,
	useLookupTruckTypes,
	useLookupTrucks,
} from "./helpers";
import { useDialog } from "./helpersReact";
import { useRecommendationsDialog } from "./useRecommendationsDialog";

type LegSelfFormProps = {
	id?: number;
	jobId?: number;
	onSubmit?: () => void;
	isRecEnabled?: boolean;
	onRecommendationsButtonClick?: () => void;
	onChange?: (data: Leg) => void;
};
const LegSelfForm = ({
	id,
	jobId,
	onSubmit,
	onRecommendationsButtonClick,
	isRecEnabled,
	onChange,
}: LegSelfFormProps) => {
	const data = useQuery({
		queryKey: ["jobApi.bff.bffLegFormDataDetail", id, jobId],
		queryFn: () => {
			if (jobId) {
				return {
					jobId,
					goodsIds: [],
					legType: NewLegType.Delivery,
					supplierInvoice: NewSupplierInvoiceType.NoInvoice,
				} satisfies LegFormDataResponse;
			}
			return jobApi.bff
				.bffLegFormDataDetail(Number(id))
				.then((data) => data.data);
		},
	});
	const update = useMutation({
		mutationKey: ["jobApi.bff.bffLegFormDataUpdate"],
		mutationFn: (data: LegFormDataResponse) =>
			jobApi.bff.bffLegFormDataCreate(data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["job-details"] });
		},
	});
	const lLegTypes = useLookupLegTypes();
	const lGoods = useLookupGoods(data.data?.jobId ?? undefined);
	const lLocations = useLookupLocations();
	const lSubcontractors = useLookupSubcontractors();
	const lDrivers = useLookupDrivers();
	const lTrucks = useLookupTrucks();
	const lTrailers = useLookupTrailers();
	const lBusinessUnits = useLookupBusinessUnits();
	const lTrailerTypes = useLookupTrailerTypes();
	const lTruckTypes = useLookupTruckTypes();
	const lSupplierInvoiceTypes = useLookupSupplierInvoiceTypes();
	const lCurrencies = useLookupCurrencies();
	if (!data.isFetchedAfterMount || !data.data?.jobId) {
		return <div>Loading...</div>;
	}
	return (
		<LegForm
			isRecEnabled={isRecEnabled}
			onRecommendationsButtonClick={onRecommendationsButtonClick}
			lLegTypes={lLegTypes}
			lGoods={lGoods}
			lLocations={lLocations}
			lSubcontractors={lSubcontractors}
			lDrivers={lDrivers}
			lTrucks={lTrucks}
			lTrailers={lTrailers}
			lBusinessUnits={lBusinessUnits}
			lTrailerTypes={lTrailerTypes}
			lTruckTypes={lTruckTypes}
			lSupplierInvoiceTypes={lSupplierInvoiceTypes}
			lCurrencies={lCurrencies}
			defaultValues={omitBy(data.data, isNil)}
			onSubmit={async (data) => {
				await toasted(
					update.mutateAsync(data as LegFormDataResponse),
					"Saving leg",
				);
				onSubmit?.();
			}}
			onChange={onChange}
		/>
	);
};

export const useLegFormDialog = () => {
	const [props, setProps] = useState<LegSelfFormProps>({});
	const [formData, setFormData] = useState<Leg>();
	const [toggleRecDialog, recDialog, isRecEnabled] = useRecommendationsDialog(
		formData,
		(data) => {
			console.log(data);
		},
	);
	const [toggleDialog, dialog] = useDialog(
		<div style={{ minWidth: 1000 }}>
			<LegSelfForm
				onSubmit={() => toggleDialog(false)}
				{...props}
				onRecommendationsButtonClick={toggleRecDialog}
				isRecEnabled={isRecEnabled}
				onChange={setFormData}
			/>
			{recDialog}
		</div>,
		props.id ? "Edit Leg" : "Add Leg",
	);
	const openDialog = (props: LegSelfFormProps) => {
		setProps(props);
		toggleDialog(true);
	};
	return [openDialog, dialog] as const;
};
