import { Button } from "@progress/kendo-react-buttons";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import { GenericDataGrid } from "./GenericDataGrid";
import {
	type LegStatusType,
	type LoadStatus,
	type NewJobStatus,
	ObjectType,
	TableNameType,
	type TypedGridColumnProps,
	jobApi,
	jobStatusNamesAndColors,
	legStatusTypeNames,
	loadStatusNames,
	objectTypeNames,
	toDateString,
} from "./helpers";
import { toCell, useAuditForm } from "./helpersReact";

type SearchResult = {
	objectType: number;
	objectTypeString: string;
	id: string;
	jobId: number | undefined;
	loadId: number | undefined;
	actualId: number;
	uniqueId: string;
	type: number;
	typeName: string;
	customerName: string;
	status: number;
	statusString: string;
	startDate: Date;
	startDateString: string;
	endDate: Date;
	endDateString: string;
	startLocationName: string;
	endLocationName: string;
	subcontractorName: string;
	driverName: string;
};
const defaultColumns: TypedGridColumnProps<SearchResult>[] = [
	{ field: "objectTypeString", title: "Object Type" },
	{ field: "statusString", title: "Status" },
	{ field: "uniqueId", title: "ID" },
	{ field: "typeName", title: "Type" },
	{ field: "customerName", title: "Customer" },
	{ field: "startLocationName", title: "Start Location" },
	{
		field: "startDate",
		title: "Start Date",
		cell: ({ dataItem }) => toCell(dataItem.startDateString),
	},
	{ field: "endLocationName", title: "End Location" },
	{
		field: "endDate",
		title: "End Date",
		cell: ({ dataItem }) => toCell(dataItem.endDateString),
	},
	{ field: "driverName", title: "Driver" },
	{ field: "subcontractorName", title: "Subcontractor" },
];

const useFetchData = (search?: string) => {
	const _search = useQuery({
		queryKey: ["jobApi.search.searchList", search],
		queryFn: async () => {
			if (!search) return [];
			return await jobApi.search.searchList({ search }).then((x) => x.data);
		},
		initialData: [],
	});
	const drivers = useMemo(
		() =>
			_search.data.map((x): SearchResult => {
				const startDate = dayjs(x.startDate).toDate();
				const endDate = dayjs(x.endDate).toDate();
				const objectType = x.objectType as ObjectType;
				let statusString = "";
				if (objectType === ObjectType.Job) {
					const status = x.status as NewJobStatus;
					statusString = jobStatusNamesAndColors[status].name;
				} else if (objectType === ObjectType.Leg) {
					const status = x.status as LegStatusType;
					statusString = legStatusTypeNames[status];
				} else if (objectType === ObjectType.Load) {
					const status = x.status as LoadStatus;
					statusString = loadStatusNames[status];
				}
				return {
					id: `${x.objectType}-${x.id}`,
					jobId: x.jobId ?? undefined,
					loadId: x.loadId ?? undefined,
					actualId: x.id,
					uniqueId: x.uniqueId ?? "?",
					objectType: x.objectType,
					objectTypeString: objectTypeNames[objectType],
					type: x.type,
					typeName: x.typeName ?? "",
					customerName: x.customerName ?? "",
					status: x.status,
					statusString: statusString,
					startDate,
					startDateString: toDateString(startDate),
					endDate,
					endDateString: toDateString(endDate),
					startLocationName: x.startLocationName ?? "",
					endLocationName: x.endLocationName ?? "",
					subcontractorName: x.subcontractorName ?? "",
					driverName: x.driverName ?? "",
				};
			}),
		[_search.data],
	);
	return { data: drivers, retry: _search.refetch, loading: _search.isFetching };
};
const useExtraColumns = (
	showAuditFor: (id: string | number, _newType?: TableNameType) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useMemo(
		(): TypedGridColumnProps<SearchResult>[] => [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					return (
						<td>
							<Button
								size="small"
								icon="eye"
								onClick={() => {
									if (dataItem.objectType === ObjectType.Job) {
										navigate(`/jobs/${dataItem.actualId}`, {
											state: { from: location.pathname },
										});
									} else if (dataItem.objectType === ObjectType.Leg) {
										if (dataItem.loadId) {
											return navigate(`/loads/${dataItem.loadId}`, {
												state: { from: location.pathname },
											});
										}
										if (dataItem.jobId) {
											return navigate(`/jobs/${dataItem.jobId}`, {
												state: { from: location.pathname },
											});
										}
										return toast.error(
											"Leg is not associated to a job or a load",
										);
									} else if (dataItem.objectType === ObjectType.Load) {
										navigate(`/loads/${dataItem.actualId}`, {
											state: { from: location.pathname },
										});
									}
								}}
							/>
							<Button
								size="small"
								icon="zoom"
								onClick={() => {
									if (dataItem.objectType === ObjectType.Job) {
										showAuditFor(dataItem.actualId, TableNameType.Job);
									} else if (dataItem.objectType === ObjectType.Leg) {
										showAuditFor(dataItem.actualId, TableNameType.Leg);
									} else if (dataItem.objectType === ObjectType.Load) {
										showAuditFor(dataItem.actualId, TableNameType.Load);
									}
								}}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		],
		[navigate, location, showAuditFor],
	);
};
export const SearchPage = () => {
	useTitle("Search");
	const [search, setSearch] = useState("");
	const { data, retry, loading } = useFetchData(search);
	const { showAuditFor, auditDialog } = useAuditForm(TableNameType.Job);
	const callbackSearch = (search: string) => {
		if (search.length < 3) return setSearch("");
		return setSearch(search);
	};
	const extraColumns = useExtraColumns(showAuditFor);
	return (
		<>
			{auditDialog}
			<GenericDataGrid
				name="Search"
				data={data}
				refresh={retry}
				loading={loading}
				defaultColumns={defaultColumns}
				callbackSearch={callbackSearch}
				extraColumns={extraColumns}
				searchPlaceholder="Search by ID, minimum 3 characters..."
			/>
		</>
	);
};
