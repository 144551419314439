import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import {
	arrowsAxesIcon,
	chevronDownIcon,
	chevronUpIcon,
	commentRemoveIcon,
	pencilIcon,
	printIcon,
} from "@progress/kendo-svg-icons";
import { useMemo, useState } from "react";
import { DetailsDisplayLocation } from "./DetailsDisplayLocation";
import { DetailsInformationCard } from "./DetailsInformationCard";
import { DFlex } from "./GenericFlex";
import { DGrid } from "./GenericGrid";
import { JobDetailsCard } from "./JobDetailsCard";
import type { JobDetailsResponse } from "./api/JobApi";
import { toCurrency } from "./helpers";
import { DTag } from "./helpersReact";

type JobDetailsGoodsProps = {
	data: JobDetailsResponse;
	onSplitGoodsClicked: () => void;
	onEditGoodClicked: (id: number) => void;
	onRemoveGoodClicked: (id: number) => void;
	onGenerateCMRClicked: (id: number) => void;
	onGenerateCargoLabelClicked: (id: number) => void;
};
export const JobDetailsGoods = ({
	data,
	onSplitGoodsClicked,
	onEditGoodClicked,
	onRemoveGoodClicked,
	onGenerateCMRClicked,
	onGenerateCargoLabelClicked,
}: JobDetailsGoodsProps) => {
	const [expanded, setExpanded] = useState<number>();
	const totalRevenue = useMemo(
		() => data.goods.reduce((acc, x) => acc + x.revenue, 0),
		[data.goods],
	);
	return (
		<JobDetailsCard
			title={`Goods (${data.goods.length}) - ${toCurrency(
				totalRevenue,
				data.currencyCode,
			)}`}
			buttons={
				<Button
					svgIcon={arrowsAxesIcon}
					themeColor="primary"
					onClick={onSplitGoodsClicked}
				>
					Split Goods
				</Button>
			}
		>
			{data.goods.map((x) => (
				<ExpansionPanel
					key={x.id}
					title={
						<DFlex spaceBetween center>
							<div>{x.name}</div>
							<DFlex gap="8px" center>
								<DTag>{toCurrency(x.revenue, data.currencyCode)}</DTag>
								<Button
									svgIcon={pencilIcon}
									onClick={(e) => {
										e.stopPropagation();
										onEditGoodClicked(x.id);
									}}
								/>
								<Button
									svgIcon={commentRemoveIcon}
									onClick={(e) => {
										e.stopPropagation();
										onRemoveGoodClicked(x.id);
									}}
								/>
							</DFlex>
						</DFlex>
					}
					expanded={expanded === x.id}
					onAction={() => setExpanded(x.id === expanded ? undefined : x.id)}
				>
					{/* TODO: fix the reveal not revealing */}
					<Reveal>
						{expanded === x.id && (
							<ExpansionPanelContent>
								<DGrid columns="repeat(2, 1fr)" gap="8px">
									<DetailsInformationCard
										icon={chevronUpIcon}
										title="Start Location"
										value={<DetailsDisplayLocation {...x.collectionLocation} />}
									/>
									<DetailsInformationCard
										icon={chevronDownIcon}
										title="End Location"
										value={<DetailsDisplayLocation {...x.deliveryLocation} />}
									/>
								</DGrid>
								<DFlex spaceBetween>
									Goods ID: <b>{x.uniqueId}</b>
								</DFlex>
								<DFlex spaceBetween>
									Special Requirements:{" "}
									<b>
										{x.constraints.length
											? x.constraints.map((c) => c.name).join(", ")
											: "-"}
									</b>
								</DFlex>
								<DFlex spaceBetween>
									Revenue: <b>{toCurrency(x.revenue, data.currencyCode)}</b>
								</DFlex>
								<DFlex spaceBetween>
									Quantity: <b>{x.quantity}</b>
								</DFlex>
								<DFlex spaceBetween>
									Weight: <b>{x.weight} kg</b>
								</DFlex>
								<DFlex spaceBetween>
									Notes: <b>{x.notes}</b>
								</DFlex>
								<DFlex flexEnd>
									<Button
										svgIcon={printIcon}
										themeColor="primary"
										onClick={() => onGenerateCMRClicked(x.id)}
									>
										Generate CMR
									</Button>
									<Button
										svgIcon={printIcon}
										themeColor="primary"
										onClick={() => onGenerateCargoLabelClicked(x.id)}
									>
										Generate Cargo Label
									</Button>
								</DFlex>
							</ExpansionPanelContent>
						)}
					</Reveal>
				</ExpansionPanel>
			))}
		</JobDetailsCard>
	);
};
